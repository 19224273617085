import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/seo"

import {
  CreditCard,
  Phone,
  ArrowLeftRight,
  HouseDoor,
  People,
} from "react-bootstrap-icons"
import { Helmet } from "react-helmet"

function Partners() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Helmet>
        <title>Partners & Integrations - Inspection Support Network</title>
      </Helmet>

      <SEO description="Our partners help us deliver outstanding value and service to home inspector." />

      <Container>
        <Header activepage="| Partners" />
      </Container>
      <Container className={'legacy-page-spacer'}>
        <Row style={{ paddingTop: "80px" }}>
          <Col sm={12} md={12}>
            <h1 style={{ textAlign: "center",fontSize: "2.25rem;" }}>Partners</h1>
          </Col>
        </Row>
        <Row style={{}}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Porch</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/porch.png"
              />
              <p>
                Porch is an online website that connects homeowners with local
                home improvement contractors. The site also features advice
                articles, cost guides, and online booking for over 160 common
                home improvement, maintenance, and repair projects.
              </p>
              <p>
                Our vision: To realize the potential of every home. Our mission:
                Every project done, every customer satisfied. Home is where your
                dreams live. We built Porch to help turn those dreams into days
                that you can live and cherish.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.porch.com">www.porch.com</a>
                  <br />
                  <Phone /> (855) 270-3272
                  <br />
                  <ArrowLeftRight /> Home Inspectors can offer the Porch Home
                  Assistant Gold to all of their clients, for free, through the
                  ISN, and in turn have their ISN fees sponsored.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Guardian Credit Card Processing</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "250px" }}
                src="https://s3.us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/guardian-new.png"
              />
              <p>
                The Inspection Support Network has established a valuable
                working relationship with Guardian Inspection Payments for your
                credit card processing needs! They continue to offer the best
                pricing and customer support for all of our ISN users!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href=" https://www.inspectionpayments.com">
                     www.inspectionpayments.com
                  </a>
                  <br />
                  <Phone /> (800) 608-7363
                  <br />
                  <CreditCard variant="large" /> The ISN works seamlessly with
                  Guardian’s gateway (authorize.net).
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Inspector Nexus</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "150px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/nexus.png"
              />
              <p>
                Inspector Nexus is the most intuitive and cost-effective home
                inspection software on the market.
              </p>
              <p>
                Featuring a clean, modern interface, Inspector Nexus allows you
                to quickly mark defects and take photos and videos during your
                home inspections. With build-as-you-go templates and an
                intuitive design, you'll produce appealing, modern, web-based
                inspection reports that clients and agents love, all in record
                time.
              </p>
              <p>
                The Inspector Nexus property inspection app is designed to sync
                seamlessly between all of your mobile phones, tablets, and
                desktop computers. Its web-based interface makes it compatible
                with Mac, PC, or any operating system with a web browser.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://web.inspectornexus.com/">
                    web.inspectornexus.com
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>ACC (America's Call Center)</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/acc.png"
              />
              <p>
                Serving home inspectors (like you!) since 1998, we help increase
                your revenue, maximize your professional image, and make your
                life easier, giving you the competitive edge. We are not a call
                center, but your personal office, handling your calls, email
                leads, online orders, and bookings the way you want them done,
                always going above-&-beyond for you. To your callers, we are
                your friendly and very knowledgeable office right down the
                street!
                <br />
                <br />
                And our service to you more than pays for itself…no more missed
                calls, your callers are impressed, more inspections for you.
                Plus we do the follow up calls and legwork to ensure your
                inspection bookings are polished up, finished up, and ready to
                go for you, further making your life easier, taking stress and
                work off your shoulders. We are your trusty office, always going
                the extra mile for you, in every way, all the time. And, as our
                Boss, you are always in control. You even get your own small
                office team assigned to you and your own account manager.
                <br />
                <br />
                Don’t settle for less with poor imitators, try us out, there is
                no service agreement locking you in. And it is so easy to get
                started. Give us a call or visit our website to contact us
                today!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.americascallcenter.com">
                    www.americascallcenter.com{" "}
                  </a>
                  <br />
                  <Phone /> (888) 462-6153
                  <br />
                  <People variant="large" /> America’s Call Center books your
                  orders, pushes them into your ISN via a seamless
                  state-of-the-art two-way synchronization, allowing your ISN to
                  perform its duties automatically, no extra work for you, and
                  without you having to assign additional users. They also
                  handle the follow up calls, emails, and communication with the
                  parties associated with the inspection (clients, agents, 3rd
                  party services) to ensure your inspection appointments
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Palm-Tech</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/palm-tech-logo.png"
              />
              <p>
                Fast – Create your home inspection reports as you inspect and
                eliminate the need for retyping reports. Record your inspection
                results as you inspect. Flexible – With Palm-Tech, you have
                control! You control the inspection points, all of the language
                and the way your home inspection report is presented and
                delivered. Easy to learn – Attend one of our free training
                webinars. In 60 minutes, we will teach you what you need to know
                to use Palm-Tech for your home inspections! It is that easy.
                Customer Service – Call us during business hours and you get a
                live voice in the US ready to help. Call us today at
                888-736-2462 and see for yourself.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://palm-tech.com/">https://palm-tech.com/</a>
                  <br />
                  <Phone />
                  (888) 736-2462
                  <br />
                  <ArrowLeftRight variant="large" /> Palm-Tech will pull all of
                  the inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Home Inspector Pro</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "200px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/hip.png"
              />
              <p>
                Includes Residential, Commercial, Mold, Septic, Pool & More!
                Canadian, French, Spanish & Texas TREC 7-3 Home Inspection
                Residential Templates Included. ALL inspectors receive 3 months
                free of our report upload and website hosting services. A few of
                the features:
                <br />
                <br />
                Easily create a template for every type of inspection you
                perform. For example load one template for a commercial
                inspection then switch to your residential home inspection
                template for your next job.
                <br />
                <br />
                Spell check in 5 languages integrated into every portion of the
                program.
                <br />
                <br />
                Designed for a Tablet PC like the Microsoft Surface Pro, iPad,
                iPhone, and Android devices to almost eliminate the need for
                your keyboard during an inspection yet the program works great
                on a normal laptop.
                <br />
                <br />
                Ability to upload inspection reports for real estate agents,
                home buyer/seller, and you to view for up to 5 years.
                <br />
                Allows you to upload reports and email a link to the report in
                one easy step.
                <br />
                Reports are easy to for the home buyer/seller to read and Home
                Inspector Pro includes a table of contents integrated into the
                report summary for maximum clarity.
                <br />
                Add unlimited photographs anywhere in your report, edit them
                within the program. Add hundreds within seconds.
                <br />
                Works great with thermal images.
                <br />
                Reorder pages, control page breaks and create colored borders.
                <br />
                Specific template for Texas (TREC 7-3) inspectors.
                <br />
                Runs on Mac, iPhone, iPad, Android, Windows XP through Windows 8
                (32 and 64 bit machines)!
                <br />
                Easy to use commercial and home inspection software that runs on
                Windows, Android, Mac or Linux!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.homeinspectorpro.com">
                    www.homeinspectorpro.com
                  </a>
                  <br />
                  <Phone /> (888) 750-4777
                  <br />
                  <ArrowLeftRight variant="large" /> the HIP software will pull
                  all of the inspection information from the ISN so you do not
                  have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>RecallChek</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/recallchek.png"
              />
              <p>
                After taking your order on Inspection Support, information
                automatically transfers to your RecallChek phone app. All you
                have to do from your RecallChek App is take a picture of the
                appliance and data tags. Then with a quick click send to
                RecallChek, no typing needed! You can even tell ISN what
                inspection types you do NOT want to send over such as investors,
                re-inspections or insurance
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.recallchek.com/">
                    https://www.recallchek.com/
                  </a>
                  <br />
                  <Phone />1 (800) 544-8156
                  <br />
                  <ArrowLeftRight variant="large" /> Inspection Support Network
                  and the Inspector Services Group is constantly working to help
                  you grow your business all while saving you time. Offering
                  RecallChek is fast becoming a “standard” for growing
                  inspection companies. Offering only a basic inspection without
                  other services allows others in your marketplace an advantage.
                  Find out how ISG can help you grow your business.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>RadStar / RadonAway</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "250px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/radstar-alpha.png"
              />
              <p>
                With 3 models and 1 revolutionary technology, RadStar Alpha
                Continuous Radon Monitors equip home inspectors with the modern
                communication tools needed to add radon testing to inspections,
                seamlessly. Managing radon tests, templates, and reports has
                never been easier or more efficient with the custom Radon
                Management Cloud. So why not increase your revenue up to $150
                per home inspection?
              </p>
              <p>
                <strong>
                  Some of the features of this integration include:
                </strong>
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Customize Inspection Types and/or Services Trigger Orders
                to be sent to the RMC
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Select ISN orders from within the RMC mobile application
                when starting a radon test
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Post official radon reports as Attachments to Orders in
                ISN automatically
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Email radon reports to interested parties pulled from
                your ISN orders
              </p>
              <p>
                Give us a call or visit our website and speak with a certified
                radon testing expert to see how RadStar Alpha will benefit your
                business!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://radstar.accustarlabs.com/">
                    Radstaralpha.com
                  </a>
                  <br />
                  <Phone />1 (800) 767-3703
                  <br />
                  <ArrowLeftRight variant="large" /> RadStar Alpha will pull all
                  of the inspection information from the ISN so you do not have
                  to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Air Chek</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "250px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/airchek-logo.jpg"
              />
              <p>
                Millions of professionals have relied on our radon testing
                products for their ease-of-use and accuracy. You can too! Our
                nationally accredited labs have certified radon testing experts
                on the team to deliver proven solutions throughout the world.
                Air Chek’s integration with ISN’s platform allows customers to
                enter and manage their Air Chek test kit data directly through
                their ISN account.
              </p>
              <p>
                <strong>
                  Some of the features of this integration include:
                </strong>
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Air Chek serial numbers will automatically be available
                for selection within your ISN account after your initial account
                setup is done with Air Chek
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Enter all test details for Air Chek test kits directly in
                your ISN account
              </p>
              <p style={{ margin: "3px" }}>
                &bull; Post official radon reports as Attachments to Orders in
                ISN automatically
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://Radon-pro.com/">Radon-pro.com</a>
                  <br />
                  <Phone />1 (800) 247-2435
                  <br />
                  <ArrowLeftRight variant="large" /> Air Chek will pull all of
                  the inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>3D Report Writer</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/3dlogo.png"
              />
              <p>
                Here’s a sampling of Report Writer features…
                <ul>
                  <li style={{ fontSize: "14px" }}>
                    Single window interface speeds on-screen report navigation
                    and completion
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Insert all the digital photos you need for every line of the
                    report. No Limit!
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Apply annotations such as colored Arrows, circles, boxes, or
                    text to Photos and use other image editing tools directly
                    from 3D
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Include Diagrams or Schematics in reports to eliminate
                    contractor callbacks
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Edit selected report comments directly. No need to open
                    additional Windows
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Enhance your text with bold, italics, underlining, and color
                    anywhere in reports and summaries
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Our Actions Menu lets you create shortcuts to perform tasks,
                    gain access to data or even visit favorite webpages online
                    with a single click, right from 3D
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Add, delete or edit standard comments instantly, either
                    permanently or for one report
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    3D automatically saves your reports at timed intervals so
                    you don’t lose data!
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Spell Checker in American English, British English, Spanish,
                    Dutch, and French
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Includes document editor and sample documents to get you
                    started
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Merge report info or Office Management data into documents
                    automatically
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Create a Summary Report on-the-fly, based on any criteria
                    selected.
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Fully customize report appearance templates- Add company
                    letterheads
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Instantly preview finished report and summary letter…
                    anytime!
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    PDF Writer included for emailing
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Upload reports directly to your website, or email them to
                    your clients
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Include third-party handouts, attachments, videos, extra
                    photos with your delivered report
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Works seamlessly with included mobile Pocket3D reporting
                    module for compatible PDAs and phones
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Bookmark incomplete findings for review before leaving site
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Inspector Notes for each field in a report- Access handy
                    inspection reminders, codes, or record info that you don’t
                    want printed on reports.
                  </li>
                  <li style={{ fontSize: "14px" }}>
                    Easy Installation of additional third-party form designs and
                    content packages
                  </li>
                </ul>
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.3dinspection.com">
                    https://www.3dinspection.com
                  </a>
                  <br />
                  <Phone />
                  (888) 750-4777
                  <br />
                  <ArrowLeftRight variant="large" /> 3D Report Writer will pull
                  all of the inspection information from the ISN so you do not
                  have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>WhisperReporter</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/whisper.png"
              />
              <p>
                WhisperReporter is designed to meet the needs of real estate
                inspectors throughout the world by providing complete report
                layout customizations to create virtually any report desired.
                Several templates are provided, including TREC 7-3 and SPCS/T-4
                (WDI) for Texas, generic home and commercial inspection
                templates, with more to come.
                https://whispersolutions.com/Portfolio/WhisperReporter
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.whispersolutions.com">
                    www.whispersolutions.com
                  </a>
                  <br />
                  <Phone />
                  (210) 446-7512
                  <br />
                  <ArrowLeftRight variant="large" /> Whisper Report Writer will
                  pull all of the inspection information from the ISN so you do
                  not have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>InspectIt – ReportPlus</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "150px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/inspectit.png"
              />
              <p>
                Book your inspection with the Inspection Support Network, then
                push all the information to your InspectIt / ReportPlus report
                writer with just a “click”!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor /> <a href="https://www.ahit.com">www.ahit.com</a>
                  <br />
                  <Phone />1 (800) 441-9411
                  <br />
                  <ArrowLeftRight variant="large" /> ReportPlus will pull all of
                  the inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>My Report Writer</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/myreportwriter.png"
              />
              <p>
                Sync the information in your ISN to My Report Writer easily!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.myreportwriter.com">
                    www.myreportwriter.com
                  </a>
                  <br />
                  <Phone />1 (877) 316-0600
                  <br />
                  <ArrowLeftRight variant="large" /> My Report Writer will pull
                  all of the inspection information from the ISN so you do not
                  have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Horizon</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "300px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/horizon2.jpeg"
              />
              <p>
              When you spend hours trying to ﬁx what your software should have gotten right in the ﬁrst place, that wastes your time and your money. Thanks to over 40 years of industry experience, Horizon takes the pain out of report writing. We get you up and running quickly and effectively, with amazing reports that clients and agents love.<br/>
              With Horizon, you’re choosing the most simple, professional and comprehensive home inspection software on the market.
              </p>
              <ul>
              <li>Integrated scheduling and business tools to help streamline your day</li>
              <li>Built-in sales reporting</li>
              <li>Reports feature rich illustrations, defect implications and links to hundreds of technical articles - all fully customizable.</li>
              <li>Collect all your data on site with the Horizon mobile app for iOS, Android and Windows</li>
              <li>Minimal set up time – comes complete with report items ready to use</li>
              <li>Automated marketing tools</li>
              <li>All of your data is stored in the cloud for maximum security, and no one will take advantage by reaching out to your clients to sell them anything.</li>
              <li>Free training & support included</li>
              <li>Cloud services for innovations, updates and storage</li>
              <li>No add -on fees</li>
              </ul>
              <p>Call 1-866-303-7999 or visit our website and find out how Horizon can help you take your inspection business to the next level.</p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.carsondunlop.com">
                    www.carsondunlop.com
                  </a>
                  <br />
                  <Phone />
                  800-268-7070
                  <br />
                  <ArrowLeftRight variant="large" /> Horizon will pull all of
                  the inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>HomeGauge</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/homegauge.png"
              />
              <p>
                HomeGauge makes it easy to create professional home inspection
                reports and print them onsite or deliver them online. HomeGauge
                comes standard with templates for many different types of
                properties, including single family homes, multi-family,
                commercial buildings, energy, condos, mold, four point
                inspections, new construction phases, and also includes support
                for the Texas (TREC) format. In addition, you can create your
                own templates for just about any type of inspection you may need
                to perform. HomeGauge offers technical support and also tips on
                marketing your business. HomeGauge home inspection software is
                used by inspectors across the US and Canada, and is widely
                accepted by all the major trade associations.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.homegauge.com">www.homegauge.com</a>
                  <br />
                  <Phone />
                  828-254-2030
                  <br />
                  <ArrowLeftRight variant="large" /> HomeGauge will pull all of
                  the inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Spectacular Home Inspection System</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/spectacular.png"
              />
              <p>
                Great Customer Service Inspect, Generate, and Deliver the report
                on-site Internet not required to perform the inspection Point &
                Tap to add comments to the report. Minimal typing in the field
                Integration with free Dropbox cloud services Inspection Support
                Network (ISN) integration Convert your existing & familiar
                library from another program to Spectacular Photos with comments
                as you inspect. No wasting time associating photos after the
                inspection Email, print, Dropbox, or ISN the report to the
                client on-site Edit all boilerplate text and all comments Have
                your clients sign your Pre-Inspection Agreement on the iPad Sort
                sections and comments in the app the way they’ll appear on the
                report Invoice/Receipt generation 7000+ narratives Create
                templates Mark comments with severity level color codes Screen
                by screen help manual for easy learning Pricing plans that fit
                your business model Texas TREC 7-3 Report Format available
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.spectacularhis.com/features.html">
                    https://www.spectacularhis.com/features.html
                  </a>
                  <br />
                  <Phone />
                  818-642-8726
                  <br />
                  <ArrowLeftRight variant="large" />
                  Spectacular Home Inspection System will pull all of the
                  inspection information from the ISN so you do not have to
                  re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Interspect Software-Onclarity</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/interspect.png"
              />
              <p>
                Interspect Highlights Create an impressive inspection report
                with the industry’s easiest to use “Voice Control” or use “Point
                and Click” navigation to build, edit, print and email your
                report. Click on Report Validation and get warnings if you
                missed inspecting an important item or did not add something
                required into your inspection report. Add, edit and then re-edit
                an unlimited number of photographs in your inspection report.
                Maintain a Global Library of reference photos for use in any
                inspection. Create your own reports by adding templates that
                include your specific systems and items. Inspect in true “Room
                by Room” mode by adding rooms as you go, or inspect in
                traditional “Systems” mode, or both, in the same inspection. Use
                Voice Command Technology with the Interspect Voice Module to
                build your report while you inspect by issuing commands via a
                wireless headset. Provide foundation or roof maps with
                auto-calculated area in your inspection reports with Interspect
                Sketch Module. Great for indicating the location of WDI! Include
                technical reference drawings in your inspection reports using
                Illustratit’s Illustrations. Just import them into Interspect.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.interspectvoice.com/default.aspx">
                    https://www.interspectvoice.com/default.aspx
                  </a>
                  <br />
                  <Phone />
                  800-975-3017
                  <br />
                  <ArrowLeftRight variant="large" /> Interspect Software will
                  pull all of the inspection information from the ISN so you do
                  not have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>ReportHost</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/reporthost.png"
              />
              <p>
                ReportHost is home inspection software written by home
                inspectors for home inspectors. ReportHost’s design is easy to
                understand and use, while providing technology that is
                sophisticated. We manage your data and storage so that you can
                focus on your business: inspecting, and producing top-notch
                professional reports. Use whatever device you prefer – or
                multiple devices You choose how you work in the field: using
                your mobile device, or with pen and clipboard to finish online
                back in your office Robust content and multiple templates – use
                ours, modify them as you wish, or write your own No up-front
                investment ever – free reports to start that never expire (15
                for InterNACHI members) No hidden costs ever – you pay only for
                published reports – no fees for Cloud access, mobile device,
                multiple devices or users, backups, upgrades, templates,
                customer support etc. Unlimited users per company account
                Streamlined report writing for team inspections
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.reporthost.com/">
                    https://www.reporthost.com/
                  </a>
                  <br />
                  <Phone />
                  888-248-2156
                  <br />
                  <ArrowLeftRight variant="large" />
                  ReportHost will pull all of the inspection information from
                  the ISN so you do not have to re-type any information.
                  Additionally, a ReportHost completed inspection is integrated
                  with ISN and automatically uploads. ReportHost is fully
                  integrated.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Imfuna</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/imfuna-logo.png"
              />
              <p>
                Imfuna Home Inspector embraces the core skills of the inspection
                process, utilizes the power of mobile devices and the cloud, and
                produces professional reports with ease. Delivers a completely
                transformed approach to home inspections, with a faster,
                simpler, and more cost effective approach.
                <br />
                <br />
                Built for the residential or commercial property inspector the
                app enables you to conduct your routine non-invasive inspections
                to determine the condition of the structure and painlessly
                produce a report of your findings to all parties. Embraces the
                core skills of the inspection process and enables you to
                efficiently record deficiencies and flag items needing
                attention. Driven by templates to expedite the inspection
                process following industry standards.
                <br />
                Create your own templates or edit our off-the-shelf template
                that includes a comprehensive set of details of the property
                exterior, rooms, under house, attic, roof, and utility systems.
                Control the content, layout, and branding of your reports.
                <br />
                Significantly saves time and cost. Users report up to 75%
                overall time saving.
                <br />
                Unifies the inspection process throughout the inspection
                lifecycle.
                <br />
                Automates inspection reporting in a transparent manner from
                project start-up to close-out.
                <br />
                Standardizes data capture and reporting in a streamlined,
                efficient approach consistent with your own business practice.
                <br />
                Adaptable digital solution for your business consolidates the
                entire work flow.
                <br />
                Creates a recognized professional product that represents
                industry best practices and enhances your brand and reputation.
                <br />
                Establishes a readily accessible project history available 24/7
                for immutable reports.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.imfuna.com/home/">
                    https://www.imfuna.com/home/
                  </a>
                  <br />
                  <Phone />
                  (866) 306-0484
                  <br />
                  <ArrowLeftRight variant="large" /> Imfuna Home Inspector has a
                  fully automated 2-way integration with ISN. Pull down open
                  orders, push up finished reports Enter your Order once in the
                  ISN environment. With a single tap of a button on your mobile
                  device pull down any open orders from your ISN account.
                  Inspect away. Once you publish the inspection report, again
                  just tap a single button and the report will push up to the
                  property order a report to be emailed out of ISN to all
                  interested parties. No duplication. No redundancy. Highly
                  efficient.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>HomeHubZone</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/hhz.png"
              />
              <p>
                We threw out all preconceptions about reporting tools, and
                instead analyzed how inspectors work both during and after the
                onsite inspection. The result is a revolutionary approach to
                inspection reporting. HomeHubZone’s easy-to-use tablet software
                allows you to fill out your report room-by-room, while still
                producing a report that is organized by system. This feature
                alone can save tremendous time by eliminating the need to swap
                back and forth between screens when navigating an inspection.
                Other intelligent features include:
                <br />
                <br />
                ● An intuitive interface that minimizes clicks and keystrokes.
                <br />
                ● Dynamic Smart Templates™ that automatically jump start your
                inspection report based on publically available property
                knowledge.
                <br />
                ● Patent-pending Smart Defaults™ that learn from your own use
                and common characteristics of local properties
                <br />
                ● Effortless cloud syncing between tablet and web apps that
                happens automatically whenever you have a connection.
                <br />
                ● Numerous customization options to tailor the report the way
                you like it.
                <br />
                ● Features your buyers will love, like Smart Summary Pages,
                recall checking, and links to appliance manuals.
                <br />
                Get started FREE for 1 year!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.HomeHubZone.com">www.HomeHubZone.com</a>
                  <br />
                  <Phone />
                  1.773.701.5590 <br />
                  <ArrowLeftRight variant="large" />
                  HomeHubZone will pull all of your orders from the ISN so you
                  do not have to re-type any information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Fort Reports – Reporting Software</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/fort-reports.png"
              />
              <p>
                Elegant, intuitive design translates to a more pleasant and
                speedier experience. Start on the iPad. Finish on the iPad. On
                the spot. – No computer Laptops required
                <br />
                Clear and straightforward design features, producing simple and
                clear reports.
                <br />
                iPad’s are breakthrough technologies that bring the power of
                Apple to the palm of your hand. We amplify that knowledge.
                Designed for iPad & iPad mini.
                <br />
                Power to produce is key. Fort Reports gets it done.
                <br />
                <br />
                Draw on Pictures, (with your finger) – On the Spot
                <br />
                Edit / Add comments (B4 you forget) “Edit your lists, your way”
                – On the Spot
                <br />
                Customize everything – On the Spot
                <br />
                Thermal Pic’s using Flir one in Fort Reports – On the Spot
                <br />
                No WiFi connection required to inspect
                <br />
                Access your old reports from any device from you account. Send
                reports to any device.
                <br />
                <br />
                FREE download. FREE storage.
                <br />
                FREE sign-up. x5 FREE reports
                <br />
                NO credit card. NO obligation
                <br />
                Elegant Simplicity
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.fortreports.com ">
                    www.fortreports.com{" "}
                  </a>
                  <br />
                  <Phone />
                  800-837-0255
                  <br />
                  <ArrowLeftRight variant="large" /> Fort Reports will pull your
                  inspections out of ISN into it so that you do not need to
                  re-enter the inspection information when you start your
                  report.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Tap Inspect</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/tapinspectlogo.png"
              />
              <p>
                We help home inspectors save 1-2 hours per inspection. Don’t
                waste time writing reports after the inspection, use Tap Inspect
                to easily write effective reports while you’re performing the
                home inspection. Trusted in over 350,000 home inspections, 96%
                of home inspectors stick with Tap Inspect.
                <br />
                <br />
                ABOUT US
                <br />
                With generations of home inspection experience, we set out to
                build the report writing software that we wanted to use for our
                own inspection businesses. The Tap Inspect mobile app is the
                easiest report writing system available and allows us to save up
                to 10 hours per week by publishing the report before leaving the
                property.
                <br />
                <br />
                TIME-SAVING PROCESS
                <br />
                * Select a default or customized home inspection template
                <br />
                * Work through that template’s home inspection checklist at the
                property
                <br />
                * Take photos directly within the app while you’re performing
                the inspection
                <br />
                * Add circles, arrows, rectangles, and text to your photos to
                emphasize issues for your clients
                <br />
                * Easily write custom comments or choose from your library of
                popular comments
                <br />
                * Preview final inspection report, review comments and make
                edits
                <br />
                * Publish your report online and deliver to clients before
                leaving the inspection
                <br />
                * Get notified when clients receive the home inspection report
                <br />
                * Connect your Inspection Support Network or HomeBinder accounts
                to seamlessly synchronize scheduled inspections and finished
                reports
                <br />
                <br />
                FEATURES & VALUES
                <br />
                * Save 1-2 hours per home inspection by completing report before
                you leave.
                <br />
                * Tap Inspect is easy to learn and use with our library of demo
                videos.
                <br />
                * Our inspection reports are structured to quickly communicate
                the info clients need most.
                <br />
                * Call or email us for reliable technical support right from the
                app.
                <br />
                * Upload company logo and attachments to personalize your report
                pickup page.
                <br />* Securely back-up all reports, libraries, and templates
                on our web server in case your phone or iPad gets lost, stolen,
                or broken.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.tapinspect.com">www.tapinspect.com</a>
                  <br />
                  <ArrowLeftRight variant="large" />
                  Tap Inspect will pull your inspections out of ISN into it so
                  that you do not need to re-enter the inspection information
                  when you start your report.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>HomeWorthy</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/homeworthy.png"
              />
              <p>
                The Homeworthy Inspection System is the culmination of 25 years
                building inspection experience. Bob Sternberg, the creator of
                Homeworthy, is introducing the system publicly with a vision to
                drive the future of building inspection.
                <br />
                <br />
                The Homeworthy Inspection System brings together the synergy of
                professionals for a reliable, robust and accurate property
                inspection experience. It is a one stop solution that ticks all
                the boxes. The mobile application system is designed for
                inspectors, certifiers, builders, engineers and individuals.
                Record, store property inspection information and generate
                reports electronically while on-site.
                <br />
                <br />
                Homeworthy is proudly utilizing the excellence in inspection
                scheduling with ISN to bring the full service to the inspection
                industry by fully integrating all of our features as a one stop
                solution!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.homeworthyinspections.com ">
                    www.homeworthyinspections.com{" "}
                  </a>
                  <br />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Personalized Answering & Scheduling</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/pass.png"
              />
              <p>
                At PASS, we know how difficult it can be to be in a crawl space
                or on a roof while trying to schedule an appointment. Let the
                experts at PASS provide the sales, scheduling and virtual office
                services that will help grow your business. Our owners are also
                inspection company owners so our knowledge of inspections
                surpasses our competition. Our representatives go through
                exhaustive training including attending a live inspection. This
                provides PASS with a knowledge base that helps us to properly
                sell your business and services as if it were our own. Our
                ability to schedule directly in ISN can provide you the most up
                to date, real time information so you know what is happening
                with your schedule any time you check it. At PASS, we are
                dedicated to the success of your business! After all, our
                success depends on your company’s success.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://wescheduleit.com/about-us/ ">
                    https://wescheduleit.com/about-us/{" "}
                  </a>
                  <br />
                  <Phone />
                  866-612-7277
                  <br />
                  <ArrowLeftRight variant="large" />
                  PASS takes orders for you and adds them to your ISN. They also
                  handle the follow up calls and communication with the parties
                  associated with the inspection.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Simple Solutions Call Center</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "400px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/simplesolutions.png"
              />
              <p>
                Simple Solutions is a call center that works exclusively with
                home inspectors, just like you. We pride ourselves on our
                professionalism and knowledge of the home inspection industry.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://simplesolutionscc.com/">
                    https://simplesolutionscc.com/
                  </a>
                  <br />
                  <Phone />
                  877-867-8199
                  <br />
                  <ArrowLeftRight variant="large" />
                  Simple Solutions takes orders for you and adds them to your
                  ISN. They also handle the follow up calls and communication
                  with the parties associated with the inspection.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Calls on Call</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/calls-on-call.png"
              />
              <p>
                What is the impression your potential clients get when they call
                your business? With your incoming calls answered and resolved,
                you can spend more time doing things that make you money. With
                Calls On Call your customers will not know that you have a
                shared receptionist taking care of your phone calls. Calls On
                Call can schedule clients on your calendar, monitor your email
                and take care of light administrative tasks. You can train your
                shared receptionist as if they were your own in house
                receptionist.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://callsoncall.com/">
                    https://callsoncall.com/
                  </a>
                  <br />
                  <Phone />
                  608-210-3110 <br />
                  <ArrowLeftRight variant="large" />
                  Simple Solutions takes orders for you and adds them to your
                  ISN. They also handle the follow up calls and communication
                  with the parties associated with the inspection.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Perceptionist Call Center</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/perceptionist.png"
              />
              <p>
                Perceptionist ensures your sales opportunities never end up in
                voicemail by providing back up scheduling support during the
                days, evenings and weekends. Instead of ringing four times then
                rolling into voicemail, calls are redirected to a specially
                trained scheduling representative with three simple goals:
                <br />
                <br />
                Answer the calls you miss. Calls always ring at your office
                first….that way, you have the first opportunity to pick up the
                sale. Perceptionist replaces voicemail, not your local office.
                Schedule an appointment. The only way to stop the caller from
                shopping is to answer frequently asked questions and by
                providing them pricing and scheduling information. Only pay for
                scheduled appointments! …all other calls are FREE!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.myperceptionist.com/Services">
                    https://www.myperceptionist.com/Services
                  </a>
                  <br />
                  <Phone />
                  866-652-5968
                  <br />
                  <ArrowLeftRight variant="large" />
                  Perceptionist takes orders for you and adds them to your ISN.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Inspector Call Center</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "400px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/icc.png"
              />
              <p>
                Inspector Call Center is more than just an answering service:
                we’re your extended office. With over 20 years of experience
                within the home inspection industry, we have progressed with the
                times and gained extensive knowledge on how to add value to the
                services you provide. Give your clients the personalized,
                attentive assistance that our professional staff can offer, and
                start a reputation for building amazing customer relationships.
                <br />
                <br />
                Also, our calls are never outsourced to foreign countries! Your
                customers talk to native English speakers who live in the United
                States.
                <br />
                <br />
                We can help you schedule inspections, follow up on sales leads,
                provide customer support, assist you in selling add-on services,
                and much more. Our appointment scheduling and other services
                will be integrated within your ISN system, so you can maintain
                complete insight and control over your business. Give your
                clients the quality experience they demand, and give your
                business an edge with Inspector Call Center!
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://inspectorcallcenter.com/">
                    https://inspectorcallcenter.com/
                  </a>
                  <br />
                  <Phone />
                  470-375-2204
                  <br />
                  <ArrowLeftRight variant="large" />
                  Inspector Call Center takes orders for you and adds them to
                  your ISN.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>All Ways Answering</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/aaa.png"
              />
              <p>
                Our highly skilled team at All Ways books home inspections, make
                scheduling changes, and adds additional services to your
                inspections directly into your ISN calendar. Our operators up
                sell testing and know how to close the deal. Every inspection
                company is different so we personalize your service to fit your
                individual needs. We can text or email messages to you so you
                always have the most timely and accurate information. We can
                also call you with any urgent messages or last-minute changes.
                You tell us what you need us to do. All Ways has that down-home
                feeling while staying professional. Callers think we are sitting
                in your local office booking their home inspections. We always
                SMILE when we answer your phone. Open 24 hours a day * 365 days
                a year as people want immediate results these days and catching
                one missed booking can pay for your answering package for many
                months.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.allwaysanswering.com/">
                    https://www.allwaysanswering.com/
                  </a>
                  <br />
                  <Phone />
                  802-229-4401
                  <br />
                  <ArrowLeftRight variant="large" />
                  All Ways Answering books directly from within your ISN.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Inspector Services Group Call Center</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "400px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/isglogo.png"
              />
              <p>
                Never worry about missing a call again! With the ISG Call
                Center, you can choose from one of two plans to keep you covered
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://inspectorservicesgroup.com/callcenter">
                    https://inspectorservicesgroup.com/callcenter
                  </a>
                  <br />
                  <Phone />
                  800-544-8156
                  <br />
                  <ArrowLeftRight variant="large" />
                  Inspector Services Group takes orders for you and adds them to
                  your ISN. They also handle the follow up calls and
                  communication with the parties associated with the inspection.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Home Owners Network</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/hon-logo.png"
              />
              <p>
                New info for 2015! Right now HON is offering inspectors the most
                incredible marketing tool! Free Premium memberships ($249 value
                each) to the Home Owners Network for every one of your
                inspection clients. Add value to your inspection service and
                attract more agents and clients! Annual registration is $799 but
                as an ISN user you save $200. Join today for just $599 a year to
                receive unlimited HON Total Home Support memberships to gift to
                all your clients. You can active your HON account right from
                your ISN account. Contact us today for assistance and account
                set up.
                <br />
                <br />
                • Current ISN inspectors already registered with HON will always
                remain free on both HON and HON Pro
                <br />
                • New users to HON and HON Pro will be charged $599 or $50 a
                month.
                <br />
                • Inspectors give away FREE HON 1 year memberships ($249 retail
                value) to their inspection clients in unlimited quantity.
                <br />
                • HON Pro (live support for inspectors) is included free for
                current inspectors and included in the new pricing structure.
                Basically, use HON through ISN and get HON Pro ($49 a month
                value) FREE.
                <br />
                • Home Owners are free when they receive their membership from
                an inspector always.
                <br />
                <a href="https://www.youtube.com/watch?v=BFXcBnn_Y9g">
                  https://www.youtube.com/watch?v=BFXcBnn_Y9g
                </a>{" "}
                – Home Owners Network
                <br />
                <a href="https://www.youtube.com/watch?v=yt8xHbcYb-c">
                  https://www.youtube.com/watch?v=yt8xHbcYb-c
                </a>{" "}
                – Using HON with Inspection Support Network
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.homeownersnetwork.com/">
                    https://www.homeownersnetwork.com/
                  </a>
                  <br />
                  <Phone />
                  866-211-4662
                  <br />
                  <ArrowLeftRight variant="large" />
                  ISN Automatically submits your inspections to HON if you
                  choose.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Home Binder</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/home-binder.png"
              />
              <p>
                Add a valuable service to your home inspections and help your
                business in the process. HomeBinder is an electronic filing
                cabinet for the homeowner to store any type of information about
                their home including paint colors by room. You can add
                recommended home professionals (painters, plumbers,
                electricians), set up a maintenance schedule and even offer a
                recall notification service to your clients all for one low
                monthly fee. Plus, each maintenance reminder email (“Time to
                change the central air filter”… “Clean Gutters”) will be branded
                with your information and contact details. Use HomeBinder to
                help drive future referrals. Work with real estate agents? Let
                them know you will add their details to HomeBinder as well to
                keep them part of that homeowners records.No information is
                shared with any third parties and no future fees are due the
                homeowner. Inspect. Report. HomeBinder.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.homebinder.com/">
                    https://www.homebinder.com/
                  </a>
                  <br />
                  <Phone />
                  800-377-6915
                  <br />
                  <ArrowLeftRight variant="large" />
                  Automatically submits information to start the binder.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Applica / Home Wizard</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "500px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/applica.png"
              />
              <p>
                Home Wizard by Applica is an interactive e-Newsletter & mobile
                app that helps you grow your business by generating referrals
                and repeat business for you, through providing your clients with
                monthly personalized home care reminders, tips, and much more.
                It is very easy to synchronize ISN with Applica’s Home Wizard
                interactive e-Newsletter, which together creates an end-to-end
                solution for running and growing your home inspection business.
                Once synchronized, when you add new homeowners and real estate
                agent contacts into ISN, beginning on the first day of the
                following month, your homeowner contacts will automatically
                begin receiving your ‘homeowner’ version of the Home Wizard
                interactive e-Newsletter, and your real estate agent contacts
                will automatically begin receiving your ‘realtor’ version.
                Here’s how easy it is to setup ISN to synchronize with Applica:
                <br />
                <br />
                Step 1: Login to your Applica ‘Brand-Builder Dashboard’.
                <br />
                Step 2: Go to ‘Subscribers’, and then to ‘Synchronization’.
                <br />
                Step 3: On the Synchronization screen, add your ISN username,
                ISN password, and ISN Company Key. If you need help finding your
                Company Key, just click the link there to see a tutorial.
                <br />
                Step 4: Turn the synchronization toggle to ‘ON’. And that’s it.
                <br />
                With your synchronization turned on, every time you login to
                your Applica Brand-Builder Dashboard, it will automatically
                synchronize to your ISN account, so that you will see your
                latest ISN contacts in your Applica account. And on the first of
                every month, just before your Home Wizard e-Newsletters are sent
                out, it will also automatically synchronize to your ISN account.
                Together you have a powerful end-to-end solution that combines
                the business automation software of ISN with tools from
                Applica’s Home Wizard that will help you generate referrals and
                repeat business by keeping you in on-going contact with your
                clients.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://applica-solutions.com/">
                    https://applica-solutions.com/
                  </a>
                  <br />
                  <Phone />
                  508-281-2050
                  <br />
                  <ArrowLeftRight variant="large" />
                  Applica / Home Wizard will pull all of the client contact
                  information from the ISN so you do not have to re-type any
                  information.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Amerispec Inspection Services</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/amerispec-logo.png"
              />
              <p>
                Amerispec® Home Inspection- Highly trained home inspectors
                provide comprehensive inspection services for buyers, sellers,
                real estate agents and more.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.amerispec.com/">
                    https://www.amerispec.com/
                  </a>
                  <br />
                  <Phone />
                  877-796-5217
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>HomeTeam Inspection Services</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/hometeam.png"
              />
              <p>
                When you need a professional home inspection, trust HomeTeam,
                the leading home inspection company in North America. For more
                than 20 years, home buyers, home sellers, and real estate
                professionals have recognized HomeTeam for being fast, trusted
                and accurate. Discover the HomeTeam difference.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.hometeam.com/">
                    https://www.hometeam.com/
                  </a>
                  <br />
                  <Phone />
                  844-HOMETEAM
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>HouseMaster</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/housemaster-small.png"
              />
              <p>
                The HouseMaster home and building inspection franchise system
                was founded in 1979. Since then, we have grown to over 370 local
                offices whose inspectors have collectively performed over 2
                million inspections.
                <br />
                <br />
                We are the oldest and most respected name in home and building
                inspections throughout North America. Our staff has been
                featured numerous times in national and local broadcast as well
                as print media and online. No one is better prepared to deliver
                objective, expert home and building inspections and reports.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.housemaster.com/">
                    https://www.housemaster.com/
                  </a>
                  <br />
                  <Phone />
                  1-800-526-3939
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>WIN Home Inspections</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/win.png"
              />
              <p>
                WIN Home Inspection (WIN), founded in 1993 in Seattle,
                Washington, has developed a U.S. network of home inspection
                professionals, making it one of the fastest growing home
                inspection companies today.
                <br />
                <br />
                WIN began with home inspection business franchises in the
                Pacific Northwest and within a year, not only dominated this
                regional market but has since expanded across the U.S., with
                more than 168 locations in 28 states.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.wini.com/">https://www.wini.com/</a>
                  <br />
                  <Phone />
                  800-309-6753
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>National Property Inspections</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/npi.png"
              />
              <p>
                Founded in 1987, National Property Inspections is one of the
                oldest and most respected names in the home inspection and
                commercial building inspection business. NPI inspectors perform
                home inspections and commercial property inspections throughout
                the United States and Canada. In Canada, we operate as Global
                Property Inspections (GPI).
                <br />
                <br />
                NPI provides services to a wide variety of clients, including
                home buyers and sellers, commercial property
                investors/owners/tenants, real estate agents and attorneys,
                employee relocation companies, banks, and field service
                companies. Whether you are looking for a home
                inspection,commercial building inspection or any type of
                property inspection, NPI offers the services you need through
                our trusted, highly trained and experienced inspectors.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.npiweb.com/">https://www.npiweb.com/</a>
                  <br />
                  <Phone />
                  1-800-333-9807
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Inspect-It 1st</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/iif.png"
              />
              <p>
                Inspect-It 1st® is the Nation’s Premier Property Inspection
                Franchise, servicing buyers, sellers, real estate agents and
                others with our professional home and building inspection
                services. Since 1991, we’ve been the inspection firm of choice
                for those who want the best local inspection services. Our
                industry leading inspection report, training and experience, and
                level of client care are simply unmatched.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.inspectit1st.com/">
                    https://www.inspectit1st.com/
                  </a>
                  <br />
                  <Phone />
                  877-392-6278
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>The BrickKicker</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/brickkicker.png"
              />
              <p>
                If you are looking for the venture of starting your own home
                inspection business or you are already a home inspector who
                needs a proven, affordable franchise system, The BrickKicker
                franchise program can help! The BrickKicker has home inspection
                franchise opportunities across North America. Our home
                inspection franchise opportunity will provide you with a “Blue
                Print” for owning your own home inspection business and
                achieving the American Dream. For some, this dream becomes a
                challenge filled with enough desire and energy to carry them
                beyond the realm of current occupation toward a self-made career
                which reflects their true character and entrepreneurial spirit.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.brickkicker.com/Locations/JoinourTeam.aspx">
                    https://www.brickkicker.com/Locations/JoinourTeam.aspx
                  </a>
                  <br />
                  <Phone />
                  888-339-5425
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>A-Pro</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/apro.png"
              />
              <p>
                When A-Pro was formed in 1994, our foundation rested on a unique
                concept: Introduce the element of accountability into home
                inspection, and furnish clients with a report that offers them
                protection – not just an opinion. This idea launched “The Only
                Certified Home Inspection with Guaranteed Protection” that A-Pro
                is renowned for.
                <br />
                <br />
                Exactly how do we protect our clients from undiscovered or
                unreported issues on an inspection report? Simple. We pay for
                the repairs ourselves.
                <br />
                <br />
                A-Pro is also the first company to structure a home inspection
                that offers unique benefits to all parties concerned: the buyer,
                the seller and the real-estate professionals. In doing so, our
                inspections seek to promote successful real estate sales rather
                than hinder them.
                <br />
                <br />
                Today, A-Pro stands as a respected industry leader and as a
                continuing force for innovation in the home inspection industry.
                Our highly-trained, ISHI® & CHI® and PHI® Board Certified
                professional members of A-Pro are dedicated to providing our
                clients with the most comprehensive, advanced home inspection
                techniques in the world, bar none.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://a-pro.net/home_inspection_franchise.htm">
                    https://a-pro.net/home_inspection_franchise.htm
                  </a>
                  <br />
                  <Phone />
                  800-793-2276 ext 101
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
 <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>The Inspection Boys</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px", width: "175px" }}
                src="https://s3.us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/inspectionboys.png"
              />
              <p>The Inspection Boys started Franchising in 2017.  Being a new franchise in the inspection industry they promise to use the latest technology and marketing available.  The Inspection boys have prime territories available across the county.  They have a team of top leaders in the franchise industry on board helping new locations become profitable faster.</p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://theinspectionboys.com/home-inspector-franchise-opportunity/">
                    https://theinspectionboys.com/home-inspector-franchise-opportunity/
                  </a>
                  <br />
                  <Phone />
                  1-800-819-4403
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>1st Inspection Services</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/1stinspection.png"
              />
              <p>
                We will conduct a professional non-invasive inspection of the
                property, designed to provide a snapshot of the observable
                condition of the home and related systems at the time of
                inspection. Provide a clear concise report, in a timely manner,
                to assist our client in their decision making process.
                <br />
                <br />
                We will do this to the best of our abilities in accordance with
                the American Society of Home Inspectors (ASHI), the National
                Association of Certified Home Inspectors (NACHI), and our
                company’s Standards of Practice.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.1stinspections.com/">
                    https://www.1stinspections.com/
                  </a>
                  <br />
                  <Phone />
                  800-944-7211
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>A Buyer’s Choice Home Inspections</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/abchi.png"
              />
              <p>
                As a growing company in the midst of creating thousands of jobs,
                A Buyer’s Choice has been featured on Fox News during their “On
                the Job Hunt” feature. Now in Fourteen Countries!
                <br />
                <br />
                A Buyer’s Choice Home Inspectors provide a complete range of
                home inspection services to ensure you can make your home
                purchase with complete confidence. Our well trained,
                professional and dedicated inspectors do a thorough and complete
                inspection of homes and other buildings. Our services, done once
                and done right, will give you the peace of mind you need when
                making such an investment.
                <br />
                <br />
                A work from home franchise opportunity with A Buyer’s Choice
                Home Inspections offers you the chance to change careers, own
                your own business and become your own boss, without re-inventing
                the wheel.
                <br />
                <br />
                Our low cost franchise opportunity gives you the chance to
                fulfill your dreams of self-employment, job security and
                financial gain that comes with a proven method, without the high
                risk and uncertainty that plagues the traditional business
                marketplace.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.abuyerschoice.com/">
                    https://www.abuyerschoice.com/
                  </a>
                  <br />
                  <Phone />
                  423-994-7296
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Millionaire Inspector Community</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/millionaire.png"
              />
              <p>
                The Millionaire Inspector Community consists of inspection
                company business owners all over the country. From one man teams
                to the biggest inspection companies in the country you will
                learn how to move to your next level of success. One-on-One
                coaching, weekly conference calls, Master Mind groups, small
                group sessions and more are all part of this supportive
                community. Mike Crow the founder is an industry leader, speaker
                and trend setter. If you are truly interested in personal and
                business growth the Millionaire Inspector Community will
                surround you with the tools you need. Learning from the coaches
                and other focused business owners is an equation for your
                success.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.mikecrow.com/">
                    https://www.mikecrow.com/
                  </a>
                  <br />
                  <Phone />
                  (800) 211-3981
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>
                  InterNACHI – International Association of Certified Home
                  Inspectors
                </strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/internachibig.png"
              />
              <p>
                Inspection Support Network can automatically send over your
                client information to InterNACHI if you are part of the
                InterNACHI Buy Back program. ISN always strives to give you
                choice and make your business run smoother while being more
                streamlined. Saving you time and money.
                <a href="https://help.inspectionsupport.net/en/articles/123538-integration-internachi-buy-back-guarantee">
                  Click here for more information.
                </a>
                The International Association of Certified Home
                Inspectors—InterNACHI—is the world’s largest nonprofit
                association of residential and commercial property inspectors,
                providing education, training, certification, benefits and
                support for its members. InterNACHI-Certified Professional
                Inspectors® follow a comprehensive Standards of Practice, abide
                by a strict Code of Ethics, and take accredited Continuing
                Education courses each year, including InterNACHI’s Online
                Inspector Examination.
                <br />
                <br />
                Home inspectors, consumers, and real estate professionals can
                all find the information and support they need to make informed
                and confident decisions here at InterNACHI.org.
                <br />
                <br />
                InterNACHI… Inspected Once, Inspected Right!®
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.nachi.org/">https://www.nachi.org/</a>
                  <br />
                  <Phone />
                  (303) 502-6214
                  <br />
                  <ArrowLeftRight variant="large" />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: "0px" }}>
          <Col sm={12} md={11}>
            <div
              className="partner-group"
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dashed lightgrey",
              }}
            >
              <p>
                <strong>Small Business Inventory Management</strong>
              </p>
              <img
                className="imgnomod"
                alt="partner logo"
                style={{ float: "right", margin: "10px" }}
                src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/partners/QRInventoryLogo.png"
              />
              <p>
                Online Inventory & Asset Management
                <br />
                <br />
                Generate and print QR code labels for radon tests
                <br />
                Track where and when radon test is installed and removed by
                scanning QR code with a smartphone
                <br />
                Fill out the form that they previously filled out on paper when
                they install and remove radon test
                <br />
                Submit results to the cloud
                <br />
                Search and review radon tests that were performed
                <br />
                Review where each radon test is located and its transactions
                history
                <br />
                ISN integration points:
                <br />
                <br />
                when inspector logs in to QR Inventory mobile application, he
                can select from a list of addresses pulled out from ISN orders.
                <br />
                when he installs radon test, home address, home owner and agents
                information is pulled out from ISN and is pre-filled on the
                form.
                <br />
                <br />
                In general, QR Inventory is inventory and asset management
                software, and allows to track any kind of inventory and assets
                by scanning QR code with a smartphone, create and fill out
                custom mobile inspection forms and fill them out on a
                smartphone,access asset information on site by scanning QR code.
                Admin web based dashboard provides real time access into
                inventory stock, assets location, submitted inspection forms,
                transaction history and usage statistics.
              </p>
              <div className="subhead">
                <p>
                  <HouseDoor />{" "}
                  <a href="https://www.small-business-inventory-management.com/">
                    https://www.small-business-inventory-management.com/
                  </a>
                  <br />
                  <Phone />
                  (208)806-3300
                  <br />
                  <ArrowLeftRight variant="large" /> the ISN works seamlessly
                  with the inventory system
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}
export default Partners
